import { CryptopanicModel } from 'src/models'
import http from 'src/services/http'

type CryptopanicResponse = {
  data: CryptopanicModel
}

const API_CRYPTOPANIC = 'https://cryptopanic.com/api/v1/posts/'
const CRYPTOPANIC_TOKEN = '141fcd2383b3d902b34dcf3b029d1314bf4146cf'
// params: GetNewsLetterParams
export async function getCryptopanic(): Promise<CryptopanicResponse> {
  return http
    .get({
      url: `${API_CRYPTOPANIC}`,
      params: {
        auth_token: CRYPTOPANIC_TOKEN,
        filter: 'rising',
        public: true,
      },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      return []
    })

  const news = await http
    .get({
      url: `${API_CRYPTOPANIC}`,
      params: {
        auth_token: CRYPTOPANIC_TOKEN,
      },
    })
    .then(res => {
      if (res?.data.length === 0) {
        return []
      }
      return res.data
    })
    .catch(err => {
      return []
    })
}

import { NewsletterModel } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_NEWSLETTERS } from 'src/consts'
import { NotFoundError } from './errors'

type NewsletterResponse = {
  data: NewsletterModel[]
}

// params: GetNewsLetterParams
export async function getNewsletters(): Promise<NewsletterResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWSLETTERS}`,
      params: {
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res?.data.length === 0) {
        return []
      }
      return res.data
    })
    .catch(() => [])
}

type GetNewsletterParams = {
  slug: string
}
export function getNewsletter(
  params: GetNewsletterParams
): Promise<NewsletterModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWSLETTERS}`,
      params: {
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data[0]
    })
}

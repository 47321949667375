import { createAsyncStateHook } from './utils'
import {
  // getListItem,
  // getListItemIndex,
  getListItemReadAlso,
  getReviewContent,
} from 'src/services/listItemServices'

// export const useListItem = createAsyncStateHook('listItem', getListItem)

// export const useListItemIndex = createAsyncStateHook(  // REPLACED WITH USEREVIEWINDEX
//   'listItemIndex',
//   getListItemIndex
// )

export const useListItemReadAlso = createAsyncStateHook(
  'listReadAlso',
  getListItemReadAlso
)

export const useReviewContent = createAsyncStateHook(
  'ReviewContent',
  getReviewContent
)

import { GlossaryModel } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_GLOSSARY, API_GLOSSARY_INDEX } from 'src/consts'
import { NotFoundError } from './errors'

type GetGlossariesParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type GlossaryResponse = {
  data: GlossaryModel[]
}

export function getGlossary(
  params: GetGlossariesParams
): Promise<GlossaryResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_GLOSSARY_INDEX}`,
      params: {
        'populate[0]': 'glossaries',
        'pagination[pageSize]': 30,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }

      return res.data
    })
}

type GetGlossaryParams = {
  slug: string
}

export function getGlossaryIndex(
  params: GetGlossaryParams
): Promise<GlossaryModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_GLOSSARY}`,
      params: {
        populate: 'seo',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return { ...res.data[0].attributes }
    })
    .catch(error => {
      return []
    })
}

type GlossaryReadAlsoResponse = {
  data: ReadAlsoModel[]
}

type ReadAlsoModel = {
  title: string
  slug: string
  shortPreview: string
}

export function getGlossaryReadAlso(
  params: GetGlossaryParams
): Promise<GlossaryReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_GLOSSARY}`,
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return formatGlossaryReadAlso(res.data)
    })
    .catch(error => {
      return []
    })
}
export const formatGlossaryReadAlso = glossary => {
  const randomGlossary = glossary.sort(() => Math.random() - 0.5).slice(0, 5)

  return randomGlossary.map(item => {
    return {
      title: item.attributes.title,
      slug: item.attributes.slug,
      shortPreview: item.attributes.shortPreview,
    }
  })
}

export function getGlossarySitemap(
  params: GetGlossaryParams
): Promise<GlossaryReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_GLOSSARY}`,
      params: {
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data
    })
    .catch(error => {
      return []
    })
}

import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_PRESS_RELEASES, API_PRESS_RELEASE_BACKEND } from 'src/consts'
import { PressReleasesModel } from '../models/PressReleases'

type PressReleases = {
  data: PressReleasesModel[]
}

export async function getPressReleases(): Promise<PressReleases> {
  const dataCMS = await http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_PRESS_RELEASES}`,
      params: {
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        return []
      }
      return res.data.map(item => {
        return {
          ...item.attributes,
          id: item.id,
          published_at: item.attributes.publishedAt,
          fromCMS: true,
        }
      })
    })

  const dataBackend = await http
    .get({
      url: `${process.env.NEXT_PUBLIC_API_ENDPOINT}${API_PRESS_RELEASE_BACKEND}`,
    })
    .then(res => {
      return res.news
    })
    .catch(() => [])
  return orderArraysByDate(dataCMS, dataBackend) as any
}

const orderArraysByDate = (pressReleasesCMS, pressReleasesBackend) => {
  const pressReleases = [...pressReleasesCMS, ...pressReleasesBackend]
  return pressReleases.sort((a, b) => {
    const dateA = new Date(a.published_at)
    const dateB = new Date(b.published_at)
    return dateB.getTime() - dateA.getTime()
  })
}

import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_TAGS } from 'src/consts'
import { NotFoundError } from './errors'

type TagModel = {
  id: number
  name: string
}

type GetTagsParams = {
  page?: number
  pageSize?: number
}

type TagsResponse = {
  data: TagModel[]
}

export function getTags(): Promise<TagsResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_TAGS}`,
      params: {
        'populate[0]': 'description',
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError('No tags found')
      }

      return res.data
    })
}

import { AuthorModel } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_AUTHOR } from 'src/consts'
import { NotFoundError } from './errors'

type GetAuthorsParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type AuthorResponse = {
  data: AuthorModel[]
}

export function getAuthor(params: GetAuthorsParams): Promise<AuthorResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_AUTHOR}`,
      params: {
        'populate[0]': 'image',
        'pagination[pageSize]': 30,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }

      return res.data
    })
}

type GetAuthorParams = {
  slug: string
}

export function getAuthorIndex(params: GetAuthorParams): Promise<AuthorModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_AUTHOR}`,
      params: {
        populate: 'news, image, news.mainImage',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return { ...res.data[0].attributes }
    })
    .catch(error => {
      return []
    })
}

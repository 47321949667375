import { ListModel } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import {
  API_LIST,
  API_LIST_PINNED,
  API_LIST_CONTENT,
  API_NEWS_ARTICLE_PINNED,
} from 'src/consts'
import { NotFoundError } from './errors'

type GetListsParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type ListResponse = {
  data: ListModel[]
}

export function getList(params: GetListsParams): Promise<ListResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST}`,
      params: {
        'populate[0]':
          'lists, author, author.image, mainImage, flags, mainFlag, tags, list_index, list_index.image',
        'pagination[pageSize]': 30,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }

      return res.data
    })
}

type GetListParams = {
  slug: string
}

export function getListIndex(params: GetListParams): Promise<ListModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST}`,
      params: {
        populate:
          'seo, reviews, author, author.image, mainImage, flags, mainFlag, tags, list_index, list_index.image ',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return { ...res.data[0].attributes }
    })
    .catch(error => {
      return []
    })
}

type ListReadAlsoResponse = {
  data: ReadAlsoModel[]
}

type ReadAlsoModel = {
  title: string
  slug: string
  shortPreview: string
}

export function getListReadAlso(
  params: GetListParams
): Promise<ListReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST}`,
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return formatListReadAlso(res.data)
    })
    .catch(error => {
      return []
    })
}
export const formatListReadAlso = list => {
  const randomList = list.sort(() => Math.random() - 0.5).slice(0, 5)

  return randomList.map(item => {
    return {
      title: item.attributes.title,
      slug: item.attributes.slug,
      shortPreview: item.attributes.shortPreview,
    }
  })
}

export function getListSitemap(
  params?: GetListParams
): Promise<ListReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST}`,
      params: {
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data
    })
    .catch(error => {
      return []
    })
}
export function getListPinned(): Promise<ListModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST_PINNED}`,
      params: {
        populate: 'list.mainImage, flags, mainFlag',
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError('error load pinned')
      return res.data.attributes.list.data
    })
}

export function getNewsPinned(): Promise<ListModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWS_ARTICLE_PINNED}`,
      params: {
        populate: 'list.mainImage, flags, mainFlag',
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError('error load pinned')
      return res.data.attributes.list.data
    })
}

export function getListContent(): Promise<ListModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST_CONTENT}`,
    })
    .then(res => {
      if (res.data.length === 0)
        throw new NotFoundError('error load list content')
      return res.data.attributes.content
    })
}

import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_AVATARS } from 'src/consts'
import { NotFoundError } from './errors'
import { AvatarModel } from 'src/models/Avatar'

type AvatarsResponse = {
  data: AvatarModel[]
}

export function getAvatars(): Promise<AvatarsResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_AVATARS}`,
      params: {
        'populate[0]': 'image',
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError('No avatars found')
      }
      return res.data
    })
}

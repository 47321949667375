import { ListItemModel } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_LIST_ITEM, API_REVIEW_CONTENT } from 'src/consts'
import { NotFoundError } from './errors'

type GetListItemsParams = {
  slug: string
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type ListItemResponse = {
  data: ListItemModel[]
}

// export function getListItem(
//   params: GetListItemsParams
// ): Promise<ListItemResponse> {
//   return http
//     .get({
//       url: `${STRAPI_API_ENDPOINT}${API_LIST_ITEM}`,
//       params: {
//         'populate[0]':
//           'mainImage, list-items, autor, author.image, pros, cons, thumbnail, flag',
//         'pagination[pageSize]': 30,
//       },
//     })
//     .then(res => {
//       if (res.data.length === 0) {
//         throw new NotFoundError(params.categorySlug)
//       }
//       return res.data
//     })
// }

// export function getListItemIndex(
//   params: GetListItemsParams
// ): Promise<ListItemModel> {
//   return http
//     .get({
//       url: `${STRAPI_API_ENDPOINT}${API_LIST_ITEM}`,
//       params: {
//         populate:
//           'mainImage, seo, list-info, author, author.image, pros, cons, thumbnail, flag',
//         'filters[slug][$eq]': params.slug,
//       },
//     })
//     .then(res => {
//       if (res.data.length === 0) throw new NotFoundError(params.slug)
//       return { ...res.data[0].attributes }
//     })
//     .catch(error => {
//       return []
//     })
// }

type ListItemReadAlsoResponse = {
  data: ReadAlsoModel[]
}

type ReadAlsoModel = {
  title: string
  slug: string
  shortPreview: string
}

export function getListItemReadAlso(
  params: GetListItemsParams
): Promise<ListItemReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST_ITEM}`,
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return formatListReadAlso(res.data)
    })
    .catch(error => {
      return []
    })
}

export const formatListReadAlso = list => {
  const randomList = list.sort(() => Math.random() - 0.5).slice(0, 5)

  return randomList.map(item => {
    return {
      title: item.attributes.title,
      slug: item.attributes.slug,
      shortPreview: item.attributes.shortPreview,
    }
  })
}

export function getListItemSitemap(
  params?: GetListItemsParams
): Promise<ListItemReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST_ITEM}`,
      params: {
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data
    })
    .catch(error => {
      return []
    })
}

export function getReviewContent(): Promise<ListItemModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_REVIEW_CONTENT}`,
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError('error load pinned')
      return res.data.attributes.content
    })
}

import { EventModel } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { API_EVENTS } from 'src/consts'
import { NotFoundError } from './errors'

type GetEventsParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type EventResponse = {
  data: EventModel[]
}

export async function getEvents(
  params: GetEventsParams
): Promise<EventResponse> {
  const dataCMS = await http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_EVENTS}`,
      params: {
        populate: 'categories',
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        return []
      }
      return res.data.map(event => {
        return {
          ...event.attributes,
          id: event.id,
          nameId: event.attributes.title
            .toLowerCase()
            .replace(/[^A-za-z0-9]/g, '-')
            .replace(/-{2,}/g, '-'),
          categories: event.attributes?.categories.data.map(
            category => category.attributes.name
          ),
        }
      })
    })

  const dataBackend = await http
    .get({
      url: `${process.env.NEXT_PUBLIC_API_ENDPOINT}${API_EVENTS}`,
    })
    .then(res => {
      if (res.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }
      return res.reduce((acc, item) => {
        if (item.start_date && typeof item.start_date === 'string') {
          acc.push({
            ...item,
            start_date: item.start_date.split(' ')[0],
            end_date:
              item.end_date && typeof item.end_date === 'string'
                ? item.end_date.split(' ')[0]
                : '',
            nameId: item.title
              .toLowerCase()
              .replace(/[^A-za-z0-9]/g, '-')
              .replace(/-{2,}/g, '-'),
          })
        }
        return acc
      }, [])
    })
    .catch(err => {
      return []
    })
  return orderEventsByDate(dataCMS, dataBackend) as any
}

const orderEventsByDate = (eventsCMS, eventsBackend) => {
  const events = [...eventsCMS, ...eventsBackend]
  return events.sort((a, b) => {
    const dateA = new Date(a.start_date)
    const dateB = new Date(b.start_date)
    return dateA.getTime() - dateB.getTime()
  })
}

type GetEventParams = {
  slug: string
}

export function getEvent(params: GetEventParams): Promise<EventModel> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_EVENTS}`,
      params: {
        populate: 'categories',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length > 0) {
        return {
          id: res.data[0].id,
          ...res.data[0].attributes,
          categories: res.data[0].attributes?.categories.data.map(
            category => category.attributes.name
          ),
          fromCMS: true,
        }
      } else {
        return http
          .get({
            url: `${process.env.NEXT_PUBLIC_API_ENDPOINT}${API_EVENTS}/${params.slug}`,
          })
          .then(res => {
            const event = res.find(
              event =>
                event.title
                  .toLowerCase()
                  .replace(/[^A-za-z0-9]/g, '-')
                  .replace(/-{2,}/g, '-') === params.slug
            )
            if (!event) throw new NotFoundError(params.slug)
            return event
          })
      }
    })
}

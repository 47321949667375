import { createAsyncStateHook } from './utils'
import {
  getGlossary,
  getGlossaryIndex,
  getGlossaryReadAlso,
} from 'src/services/glossaryServices'

export const useGlossary = createAsyncStateHook('glossary', getGlossary)

export const useGlossaryIndex = createAsyncStateHook(
  'glossaryIndex',
  getGlossaryIndex
)
export const useGlossaryReadAlso = createAsyncStateHook(
  'glossaryReadAlso',
  getGlossaryReadAlso
)

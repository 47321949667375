import { createAsyncStateHook, createInfiniteAsyncStateHook } from './utils'
import {
  getLatestNewsArticle,
  getNewsArticle,
  getNewsArticlePinned,
  getNewsArticles,
} from 'src/services/newsArticleServices'

export const NEWS_ARTICLES_PAGE_SIZE = 12

export const useNewsArticles = createAsyncStateHook(
  'newsArticles',
  getNewsArticles
)

export const useNewsArticlesInfinite = createInfiniteAsyncStateHook(
  'newsArticlesInfinite',
  ({ index = 0, categorySlug }: any) => {
    const pageSize = NEWS_ARTICLES_PAGE_SIZE
    let start = index * pageSize
    let limit = pageSize

    // first page 13 elements. Next pages 12 elements
    if (index === 0) {
      limit = limit + 1
    } else {
      start = start + 1
    }

    return getNewsArticles({ start, limit, categorySlug })
  }
)

export const useNewsArticle = createAsyncStateHook(
  'newsArticle',
  getNewsArticle
)

export const useNewsArticlePinned = createAsyncStateHook(
  'newsArticlePinned',
  getNewsArticlePinned
)

export const useLatestNewsArticle = createAsyncStateHook(
  'latestNewsArticle',
  getLatestNewsArticle
)

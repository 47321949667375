import { createAsyncStateHook, createInfiniteAsyncStateHook } from './utils'
import {
  getLearnArticle,
  getLearnArticles,
  getLatestLearnArticle,
} from 'src/services/learnServices'

export const LEARN_ARTICLES_PAGE_SIZE = 12

export const useLearnArticles = createAsyncStateHook(
  'learnArticles',
  getLearnArticles
)

export const useLearnArticlesInfinite = createInfiniteAsyncStateHook(
  'newsArticlesInfinite',
  ({ index = 0, categorySlug }: any) => {
    const pageSize = LEARN_ARTICLES_PAGE_SIZE
    let start = index * pageSize
    let limit = pageSize

    // first page 13 elements. Next pages 12 elements
    if (index === 0) {
      limit = limit + 1
    } else {
      start = start + 1
    }

    return getLearnArticles({ start, limit, categorySlug })
  }
)

export const useLearnArticle = createAsyncStateHook(
  'learnArticle',
  getLearnArticle
)

// export const useNewsArticlePinned = createAsyncStateHook(
//   'newsArticlePinned',
//   getNewsArticlePinned
// )

export const useLatestLearnArticle = createAsyncStateHook(
  'latestLearnArticle',
  getLatestLearnArticle
)

import { API_LIST_INDEX, API_REVIEWS } from 'src/consts'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import { ReviewModel } from 'src/models'
import http from 'src/services/http'
import { NotFoundError } from './errors'

type GetAllReviewsParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type ReviewsResponse = {
  data: ReviewModel[]
}

export function getAllReviews(
  params: GetAllReviewsParams
): Promise<ReviewsResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_REVIEWS}`,
      params: {
        'populate[0]':
          'author, author.image, tags, ratings, rating, ratings.ratingAttribute, pros, cons, flag, list_index, thumbnail, list',
        'pagination[pageSize]': 30,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }

      return res.data
    })
}

type GetAllReviewsIndexparams = {
  slug: string
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type ReviewsIndexResponse = {
  data: ReviewModel[]
}

export function getReviewIndex(
  params: GetAllReviewsIndexparams
): Promise<ReviewsIndexResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_REVIEWS}`,
      params: {
        populate:
          'author, author.image, tags, ratings, ratings.ratingAttribute, ratings.list, pros, cons, flag, list_index, thumbnail, list, seo',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return { ...res.data[0].attributes }
    })
    .catch(error => {
      return [error]
    })
}

type GetGlossariesParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type GlossaryResponse = {
  data: ReviewModel[]
}

export function getReview(
  params: GetGlossariesParams
): Promise<GlossaryResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST_INDEX}`,
      params: {
        'populate[0]':
          'reviews, reviews.thumbnail, reviews.author, reviews.author.image, image, title, reviews.ratings.ratingAttribute',
        'pagination[pageSize]': 30,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }
      return res.data
    })
}

type GetReviewParams = {
  slug: string
}

type ReviewReadAlsoResponse = {
  data: ReadAlsoModel[]
}

type ReadAlsoModel = {
  title: string
  slug: string
  shortPreview: string
}

export function getReviewSitemap(
  params: GetReviewParams
): Promise<ReviewReadAlsoResponse> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_REVIEWS}`,
      params: {
        'pagination[pageSize]': 5000,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data
    })
    .catch(error => {
      return [error]
    })
}

type GetIconsParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

export function getIcons(params: GetIconsParams) {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LIST_INDEX}`,
      params: {
        'populate[0]': 'image, reviews, lists',
        'pagination[pageSize]': 30,
      },
    })
    .then(res => {
      if (res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }
      return res.data
    })
}

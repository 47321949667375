import { LearnArticle } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import {
  API_NEWS_ARTICLE_PINNED,
  API_LIST_PINNED,
  API_LEARN_ARTICLES,
} from 'src/consts'
import { NotFoundError } from './errors'

type GetLearnArticlesParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type LearnArticlesResponse = {
  data: LearnArticle[]
  meta: {
    pagination: {
      start: number
      limit: number
      total: number
    }
  }
}

const paginationParams = ['page', 'pageSize', 'start', 'limit']

export function getLearnArticles(
  params: GetLearnArticlesParams
): Promise<LearnArticlesResponse> {
  const reqParams = Object.entries(params).reduce((acu, [key, value]) => {
    if (paginationParams.includes(key)) {
      acu[`pagination[${key}]`] = value
    } else {
      acu[key] = value
    }
    return acu
  }, {})

  const categoryFilter = params.categorySlug
    ? { 'filters[categories][slug][$eq]': params.categorySlug }
    : {}

  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LEARN_ARTICLES}`,
      params: {
        ...reqParams,
        populate: 'mainImage,categories,categories.icon, flag',
        sort: 'publishedAt:desc',
        ...categoryFilter,
      },
    })
    .then(res => {
      if (params.categorySlug && res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }

      return res
    })
}

type GetLearnArticleParams = {
  slug: string
}
export function getLearnArticle(
  params: GetLearnArticleParams
): Promise<LearnArticle> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LEARN_ARTICLES}`,
      params: {
        populate: 'mainImage,categories.icon,seo,author, author.image, flag',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data[0]
    })
}

// export function getNewsArticlePinned(): Promise<LearnArticle> {
//   return http
//     .get({
//       url: `${STRAPI_API_ENDPOINT}${API_NEWS_ARTICLE_PINNED}`,
//       params: {
//         populate: 'news.mainImage,news.categories.icon,news.seo, flag',
//       },
//     })
//     .then(res => {
//       if (res.data.length === 0) throw new NotFoundError('error load pinned')
//       return res.data.attributes.news.data
//     })
// }

export function getLatestLearnArticle(): Promise<LearnArticle> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_LEARN_ARTICLES}`,
      params: {
        populate: 'mainImage,categories,categories.icon',
        sort: 'publishedAt:desc',
        _limit: 1,
      },
    })
    .then(res => {
      if (res.data.length === 0)
        throw new NotFoundError('No news articles found')
      return res.data[0]
    })
}

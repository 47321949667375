import { createAsyncStateHook } from './utils'
import {
  getReview,
  getAllReviews,
  getReviewIndex,
} from 'src/services/reviewServices'

export const useReview = createAsyncStateHook('review', getReview)

export const useAllReviews = createAsyncStateHook('allReviews', getAllReviews)

export const useReviewIndex = createAsyncStateHook(
  // The new hook that is used instead of "useListItemIndex"
  'reviewIndex',
  getReviewIndex
)

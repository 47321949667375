import { ListModel, NewsArticle } from 'src/models'
import http from 'src/services/http'
import { STRAPI_API_ENDPOINT } from 'src/consts/env'
import {
  API_NEWS_ARTICLES,
  API_NEWS_ARTICLE,
  API_NEWS_ARTICLE_PINNED,
  API_LIST_PINNED,
} from 'src/consts'
import { NotFoundError } from './errors'

type GetNewsArticlesParams = {
  page?: number
  pageSize?: number
  start?: number
  limit?: number
  categorySlug?: string
}

type NewsArticlesResponse = {
  data: NewsArticle[]
  meta: {
    pagination: {
      start: number
      limit: number
      total: number
    }
  }
}

const paginationParams = ['page', 'pageSize', 'start', 'limit']

export function getNewsArticles(
  params: GetNewsArticlesParams
): Promise<NewsArticlesResponse> {
  const reqParams = Object.entries(params).reduce((acu, [key, value]) => {
    if (paginationParams.includes(key)) {
      acu[`pagination[${key}]`] = value
    } else {
      acu[key] = value
    }
    return acu
  }, {})

  const categoryFilter = params.categorySlug
    ? { 'filters[categories][slug][$eq]': params.categorySlug }
    : {}

  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWS_ARTICLES}`,
      params: {
        ...reqParams,
        populate: 'mainImage,categories,categories.icon, flag',
        sort: 'publishedAt:desc',
        ...categoryFilter,
      },
    })
    .then(res => {
      if (params.categorySlug && res.data.length === 0) {
        throw new NotFoundError(params.categorySlug)
      }

      return res
    })
}

type GetNewsArticleParams = {
  slug: string
}
export function getNewsArticle(
  params: GetNewsArticleParams
): Promise<NewsArticle> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWS_ARTICLE}`,
      params: {
        populate: 'mainImage,categories.icon,seo,author, author.image, flag',
        'filters[slug][$eq]': params.slug,
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError(params.slug)
      return res.data[0]
    })
}

export function getNewsArticlePinned(): Promise<NewsArticle> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWS_ARTICLE_PINNED}`,
      params: {
        populate: 'news.mainImage,news.categories.icon,news.seo, flag',
      },
    })
    .then(res => {
      if (res.data.length === 0) throw new NotFoundError('error load pinned')
      return res.data.attributes.news.data
    })
}

export function getLatestNewsArticle(): Promise<NewsArticle> {
  return http
    .get({
      url: `${STRAPI_API_ENDPOINT}${API_NEWS_ARTICLES}`,
      params: {
        populate: 'mainImage,categories,categories.icon',
        sort: 'publishedAt:desc',
        _limit: 1,
      },
    })
    .then(res => {
      if (res.data.length === 0)
        throw new NotFoundError('No news articles found')
      return res.data[0]
    })
}
